::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 124, 17, 0.3);
}

::-webkit-scrollbar-thumb {
  background: rgba(5, 107, 31, 0.562);
}

::-moz-scrollbar {
  width: 7px;
}

::-moz-scrollbar-track {
  -moz-box-shadow: inset 0 0 6px rgba(0, 114, 34, 0.3);
}

::-moz-scrollbar-thumb {
  background: rgba(5, 107, 31, 0.562);
}

.MuiTablePagination-toolbar, .MuiDataGrid-footerContainer {
  min-height: 50px !important;
}
