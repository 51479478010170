.top-menu li {
  display: inline-block;
  position: relative;
}

.top-menu li.dropdown:hover > .sub-menu {
  display: block;
}

.top-menu li a {
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: #f4f4f4;
  text-transform: uppercase;
  padding: 8px 15px;
  border-left: 1px solid #000a042d;
}

.top-menu li a:hover {
  background: #005803;
}

.top-menu .sub-menu {
  display: none;
}

.sub-menu {
  position: absolute;
  left: 0px;
  list-style-type: none;
  display: block;
  width: 200px;
  z-index: 9;
}

.sub-menu li {
  display: block;
}

.sub-menu li a {
  display: block;
  background: #006203;
  color: #f4f4f4;
  border: 1px solid #000a042d;
  padding: 8px 10px;
  margin-top: -1px;
}

.top-menu .sub-menu > li.dropdown:hover > .sub-menu {
  display: block;
}

.top-menu .sub-menu .sub-menu {
  position: absolute;
  left: 200px;
  margin-left: -1px;
  top: 1px;
}
