@import "constant";

html {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
  padding: 0;
}

.login-left {
  height: 100vh;
  opacity: 0.85;
  width: 100%;
  background: #1d365d url("../images/login-bg.svg") no-repeat center;
}

.bg-white{
  background-color: #fff;
}

.title-box table {
  width: 100%;
  border-collapse: collapse;
}

.title-box table tr td {
  border: 1px solid #ddd;
  padding: 5px;
}

.layout {
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 15px 0;
  height: calc(100vh - 114px);
  background: #ececec url('../images/body_bg.png');
}

.header {
  background: #02910d url('../images/body_bg.png');
}

.container {
  width: $body-width;
  margin: 0 auto;
}

.chart-box {
  border: 1px solid #ddd;
  border-radius: 1px;
  padding-bottom: 10px;
  font-size: 10px;
}

.chart-box h2 {
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  padding: 8px 10px;
  color: #555;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fff;
}

.chart-box .chart-content {
  padding: 20px 15px 25px 0;
}

.eror-page {
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  padding: 90px 0;
}

.eror-page h1 {
  font-weight: bold;
  font-size: 60px;
}

.eror-page p {
  font-size: 15px;
}

.logo {
  width: 220px;
  margin: 0 auto 50px 20px;
}






